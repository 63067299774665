import useGlobalHook from "use-global-hook"
import React from "react"

const initialState = {
    currentUser: {},
    userLoggedIn: false,
    accountType: 'tenant',
    userCheckComplete: false,
    rentalHistoryChange: false,
    propertyChange: false,
    tenantChange: false,
    documentChange: false,
    applicationsChange: false,
    categoriesChange: false,
    inboxChange: false,
    currentId: "",
    isDemo: false,
    hasProceeded: false,
    itemOnEdit: {},
    hasFilledExperience: false,
    userChange: false,
    lang: 'en',
    dossierUser: {viewer: "owner", user_id: ""}
}

export const actions = {
    setCurrentUser: (store, user) => {
        store.setState({currentUser: user})
    },
    setDossierUser: (store, user) => {
        store.setState({dossierUser: user})
    },
    setItemOnEdit: (store, item) => {
        store.setState({itemOnEdit: item})
    },
    setHasProceeded: (store, item) => {
        store.setState({hasProceeded: item})
    },
    setHasFilledExperience: (store, item) => {
        store.setState({hasFilledExperience: item})
    },
    setUserLoggedIn: (store, loggedIn) => {
        store.setState({userLoggedIn: loggedIn})
    },
    setAccountType: (store, type) => {
        store.setState({accountType: type})
    },
    setUserCheckComplete: (store, complete) => {
        store.setState({userCheckComplete: complete})
    },
    setRentalHistoryChange: (store, hasChanged) => {
        store.setState({rentalHistoryChange: hasChanged})
    },
    setDocumentChange: (store, hasChanged) => {
        store.setState({documentChange: hasChanged})
    },
    setPropertyChange: (store, hasChanged) => {
        store.setState({propertyChange: hasChanged})
    },
    setTenantChange: (store, hasChanged) => {
        store.setState({tenantChange: hasChanged})
    },
    setApplicationsChange: (store, hasChanged) => {
        store.setState({applicationsChange: hasChanged})
    },
    setCategoriesChange: (store, hasChanged) => {
        store.setState({categoriesChange: hasChanged})
    },
    setUserChange: (store, hasChanged) => {
        store.setState({userChange: hasChanged})
    },
    setInboxChange: (store, hasChanged) => {
        store.setState({inboxChange: hasChanged})
    },
    setCurrentID: (store, id) => {
        store.setState({currentId: id})
    },
    setIsDemo: (store, isDemo) => {
        store.setState({isDemo: isDemo})
    },
    setLang: (store, lang) => {
        store.setState({lang: lang})
    },
}

export const useGlobal = useGlobalHook(React, initialState, actions)
