import React, {useEffect, useState} from "react"

import {Card, CardBody, Container, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import classnames from "classnames"
import ProgressView from "../../components/ProgressView"
import {PageLoader} from "../../components/PageLoader"
import TMPButton from "../../components/TMPButton"
import DetailsComponent from "../../components/DetailsComponent"
import useCountries from "../../hooks/useCountries"

const styles = {
    tabStyle: {
        cursor: "pointer"
    }
}


const CountryDetail = ({match, location, ...rest}) => {
    const {id} = match.params
    const {findCountry, country, isLoading, activateCountry} = useCountries()

    const [activeTab, setActiveTab] = useState(0)
    const toggle = tab => setActiveTab(tab)


    useEffect(() => {
        findCountry(id)
    }, [])

    return (
        <React.Fragment>
            {country ?
                <div className="page-content">
                    <MetaTitle title={country.name}/>
                    <Container fluid={true}>
                        <Breadcrumbs title="Dashboard" breadcrumbItem={country.name} {...rest}/>

                        <Card>
                            <CardBody>

                                <div className={"mb-2 mt-2 d-flex justify-content-end"}>
                                    {isLoading ? <ProgressView/> :
                                        <TMPButton
                                            outline
                                            onClick={() => {
                                                activateCountry(country.id, country?.status === 'active' ? 'inactive' : 'active')
                                            }}
                                        >
                                            {country?.status === 'active' ? "Disable" : "Activate"}
                                        </TMPButton>}
                                </div>

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 0,
                                            })}
                                            onClick={() => toggle(0)}
                                        >
                                            Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 1,
                                            })}
                                            onClick={() => toggle(1)}
                                        >
                                            Businesses
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId={0}>
                                        <DetailsComponent
                                            details={[
                                                {title: "Name", des: country.name},
                                                {title: "Code", des: country.code},
                                            ]}
                                        />
                                    </TabPane>
                                </TabContent>

                            </CardBody>
                        </Card>

                    </Container>
                </div>
                : <PageLoader/>
            }

        </React.Fragment>
    )
}

export default CountryDetail
