import React from "react"
import PropTypes from "prop-types"
import {Redirect, Route} from "react-router-dom"

const Authmiddleware = ({
                            component: Component,
                            layout: Layout,
                            isAuthProtected,
                            isLoggedIn,
                            ...rest
                        }) => {


    return (
        <Route
            {...rest}
            render={props => {

                if (isAuthProtected && !isLoggedIn) {
                    return (
                        <Redirect
                            to={{pathname: "/login", state: {from: props.location}}}
                        />
                    )
                }

                // if (!isAuthProtected && isLoggedIn) {
                //     return (
                //         <Redirect
                //             to={{pathname: "/dashboard", state: {from: props.location}}}
                //         />
                //     )
                // }


                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )
            }}
        />
    )
}

Authmiddleware.propTypes = {
    isAuthProtected: PropTypes.bool,
    component: PropTypes.any,
    location: PropTypes.object,
    layout: PropTypes.any,
}

export default Authmiddleware
