import {storeItem} from "./Storage"

export const handleUserAccess = (res, globalActions, callBack, redToAccount) => {
    let user = res.data.admin

    if (res.data.token !== undefined) {
        let token = res.data.token
        storeItem('token', token)
    }

    if (user.photo === "") {
        user.photo = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
    }

    storeItem('user', JSON.stringify(user))

    callBack()

    if (redToAccount) {
        window.location.assign(process.env.PUBLIC_URL + "/dashboard")
    }
}
