import React, {useEffect} from "react"
import useCategories from "../../hooks/useCategories"
import TMPButton from "../../components/TMPButton"
import TextField from "../../components/TextField"
import ProgressView from "../../components/ProgressView"
import {Col, Row} from "reactstrap"
import FilePicker from "../../components/FilePicker"
import {getFileUrl} from "../../util/Server"

const EditCategoryPhoto = ({category, gender}) => {

    const {isLoading, setPhoto, updateCategoryPhoto} = useCategories(false)

    useEffect(() => {

    }, [category])


    return (
        <React.Fragment>

            <Row>
                <Col md={6} className={"p-2"}>
                    <img
                        height={300}
                        src={getFileUrl(gender==='male' ? category?.photo : category?.alternative_photo)}
                    />
                </Col>
                <Col md={6}>
                    <FilePicker
                        onChange={({target}) => {
                            setPhoto(target.files[0])
                        }}
                        label={"Upload a new photo"}/>

                    <div>
                        <div>
                            {isLoading ? <ProgressView/> :
                                <TMPButton
                                    onClick={e => updateCategoryPhoto(category.id, gender)}>Save</TMPButton>}
                        </div>

                    </div>
                </Col>
            </Row>


        </React.Fragment>
    )
}

export default EditCategoryPhoto
