import PropTypes from 'prop-types'
import React, {useEffect} from "react"

import {BrowserRouter as Router, Switch} from "react-router-dom"
import {connect} from "react-redux"

// Import Routes all
import {authRoutes, userRoutes} from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import {ToastHolder} from "./util/Notify"
import useAuth from "./hooks/useAuth"


const App = props => {


  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  const {checkUser, isLoggedIn, globalState} = useAuth()

  useEffect(()=>{
    checkUser()
  },[isLoggedIn])

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              isLoggedIn={isLoggedIn}
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
              isLoggedIn={isLoggedIn}
            />
          ))}
        </Switch>
      </Router>
      <ToastHolder/>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
