import React, {useEffect} from "react"
import useCategories from "../../hooks/useCategories"
import TMPButton from "../../components/TMPButton"
import TextField from "../../components/TextField"
import ProgressView from "../../components/ProgressView"
import SelectView from "../../components/SelectView"

const EditSubCategory = ({category}) => {

    const {name, setName, isLoading, gender, setGender, active, setActive, updateSubCategory} = useCategories(false)

    useEffect(() => {
        setName(category?.name)
        setActive(category?.active)
        setGender(category?.gender)
    }, [category])


    return (
        <React.Fragment>

            <TextField
                className="form-control"
                placeholder=""
                onChange={e => setName(e.target.value)}
                value={name}
                label={"Sub category name"}/>

            <SelectView
                label={"Gender"}
                onChange={e => {
                    setGender(e.target.value)
                }}
                value={gender}>
                <option>Any</option>
                <option>Male</option>
                <option>Female</option>
            </SelectView>


            <div className="form-check form-check-end mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={e => setActive(!active)}
                    checked={active}
                />
                <label
                    className="form-check-label"
                >
                    Mark as active
                </label>
            </div>
            <div>
                <div>
                    {isLoading ? <ProgressView/> :
                        <TMPButton
                            onClick={e => updateSubCategory(category?.id)}>Save</TMPButton>}
                </div>

            </div>

        </React.Fragment>
    )
}

export default EditSubCategory
