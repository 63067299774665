import React from "react"
import {Input, Label} from "reactstrap"

const TextField = (props) => <div className="mb-3 form-floating">
    <Input
        type="text"
        className="form-control"
        {...props}
    />
    <Label>{props.label}</Label>
</div>

export default TextField