import React, {useEffect} from "react"
import useCategories from "../../hooks/useCategories"
import TMPButton from "../../components/TMPButton"
import TextField from "../../components/TextField"
import ProgressView from "../../components/ProgressView"

const EditCategory = ({category}) => {

    const {name, setName, isLoading, active, setActive, updateCategory} = useCategories(false)

    useEffect(() => {
        setName(category?.name)
        setActive(category?.active)
    }, [category])


    return (
        <React.Fragment>

            <TextField
                className="form-control"
                placeholder=""
                onChange={e => setName(e.target.value)}
                value={name}
                label={"Category name"}/>

            <div className="form-check form-check-end mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={e => setActive(!active)}
                    checked={active}
                />
                <label
                    className="form-check-label"
                >
                    Mark as active
                </label>
            </div>
            <div>
                <div>
                    {isLoading ? <ProgressView/> :
                        <TMPButton
                            onClick={e=>updateCategory(category.id)}>Save</TMPButton>}
                </div>

            </div>

        </React.Fragment>
    )
}

export default EditCategory
