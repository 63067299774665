import React from "react"

import {Card, CardBody, Col, Container, Row} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import TextField from "../../components/TextField"
import ProgressView from "../../components/ProgressView"
import {FormAlert} from "../../components/FormAlert"
import useCountries from "../../hooks/useCountries"
import FormContainer from "../../components/FormContainer"
import TMPButton from "../../components/TMPButton"

const NewCountry = props => {

    const {
        createCountry,
        name, setName,
        currency, setCurrency,
        code, setCode,
        commission, setCommission,
        activationAmount, setActivationAmount,
        minWithdrawals, setMinWithdrawals,
        minServiceCharge, setMinServiceCharge,
        paymentMethods, setPaymentMethods,
        payoutMethods, setPayoutMethods,
        earningMaturity, setEarningMaturity,
        businessReferralEarning, setBusinessReferralEarning,
        businessReferralEarningPerBooking, setBusinessReferralEarningPerBooking,
        businessReferralEarningInfluencer, setBusinessReferralEarningInfluencer,
        businessReferralEarningPerBookingInfluencer, setBusinessReferralEarningPerBookingInfluencer,
        userReferralCommission, setUserReferralCommission,
        userReferralReward, setUserReferralReward,
        userReferralCommissionInfluencer, setUserReferralCommissionInfluencer,
        userReferralRewardInfluencer, setUserReferralRewardInfluencer,
        userReferralMinSpend, setUserReferralMinSpend,
        isLoading
    } = useCountries()


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title={"Create a country"}/>
                <Container fluid={true}>
                    <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Create a country"} {...props}/>

                    <FormContainer>
                        <Card>
                            <CardBody>
                                <h4 className="card-title mb-4">Fill in the form below to add a new country</h4>

                                <Row className={"mt-0"}>
                                    <Col md={12}>
                                        <TextField
                                            label={"Name"}
                                            onChange={e => setName(e.target.value)}
                                            value={name}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Currency"}
                                            onChange={e => setCurrency(e.target.value)}
                                            value={currency}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Code"}
                                            onChange={e => setCode(e.target.value)}
                                            value={code}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Commission"}
                                            onChange={e => setCommission(e.target.value)}
                                            value={commission}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Activation amount"}
                                            onChange={e => setActivationAmount(e.target.value)}
                                            value={activationAmount}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Minimum withdrawal amount"}
                                            onChange={e => setMinWithdrawals(e.target.value)}
                                            value={minWithdrawals}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Minimum service charge"}
                                            onChange={e => setMinServiceCharge(e.target.value)}
                                            value={minServiceCharge}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Payment methods"}
                                            onChange={e => setPaymentMethods(e.target.value)}
                                            value={paymentMethods}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Payout methods"}
                                            onChange={e => setPayoutMethods(e.target.value)}
                                            value={payoutMethods}
                                        />
                                    </Col>

                                    <Col md={6}>
                                        <TextField
                                            label={"Business referral earning"}
                                            onChange={e => setBusinessReferralEarning(e.target.value)}
                                            value={businessReferralEarning}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Business referral earning per booking"}
                                            onChange={e => setBusinessReferralEarningPerBooking(e.target.value)}
                                            value={businessReferralEarningPerBooking}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Influencer business referral earning"}
                                            onChange={e => setBusinessReferralEarningInfluencer(e.target.value)}
                                            value={businessReferralEarningInfluencer}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Influencer business referral earning per booking"}
                                            onChange={e => setBusinessReferralEarningPerBookingInfluencer(e.target.value)}
                                            value={businessReferralEarningPerBookingInfluencer}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"User referral commission"}
                                            onChange={e => setUserReferralCommission(e.target.value)}
                                            value={userReferralCommission}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"User referral commission reward"}
                                            onChange={e => setUserReferralReward(e.target.value)}
                                            value={userReferralReward}
                                        />
                                    </Col>

                                    <Col md={6}>
                                        <TextField
                                            label={"Influencer user referral commission"}
                                            onChange={e => setUserReferralCommissionInfluencer(e.target.value)}
                                            value={userReferralCommissionInfluencer}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Influencer user referral commission reward"}
                                            onChange={e => setUserReferralRewardInfluencer(e.target.value)}
                                            value={userReferralRewardInfluencer}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Earning maturity in days"}
                                            onChange={e => setEarningMaturity(e.target.value)}
                                            value={earningMaturity}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"User referral minimum spend"}
                                            onChange={e => setUserReferralMinSpend(e.target.value)}
                                            value={userReferralMinSpend}
                                        />
                                    </Col>

                                </Row>

                                <hr/>

                                <div className={"float-end justify-content-center d-flex flex-row align-items-center"}>
                                    {isLoading ?
                                        <ProgressView/> :
                                        <TMPButton
                                            extraClassName={"float-end"}
                                            onClick={createCountry}>Create</TMPButton>}
                                </div>

                            </CardBody>
                        </Card>
                    </FormContainer>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NewCountry
