import React from 'react';


export default {
    STORAGE_KEY: 'TreatMePal_Admin_Key',
    APP_ID: 'TreatMePal-Front-App',
    CONNECTION_ERROR_MESSAGE: 'Cannot connect at this time',
    APP_DESCRIPTION: "TreatMePal" ,
    LANG_KEY:'lang_key',
    DEFAULT_LANG:'fr'
};