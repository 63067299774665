import React, {useState} from "react"
import {postData} from "../util/Server"
import Endpoints from "../util/Endpoints"
import {ERROR, Notify, SUCCESS} from "../util/Notify"
import {isEmpty} from "lodash"

const useBusinesses = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [businesses, setBusinesses] = useState([])
    const [business, setBusiness] = useState(null)

    const [message, setMessage] = useState('')
    const [phone, setPhone] = useState('')

    const loadBusinesses = (loadMore, query, active) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("verified", active)
        data.append("query", query)

        postData(Endpoints.LIST_BUSINESSES, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                let list = loadMore ? businesses : []
                list = list.concat(...res.data || [])
                setBusinesses(list)
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const findBusiness = (id) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("business_id", id)

        postData(Endpoints.FIND_BUSINESS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                setBusiness(res.data)
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const checkBusinessSetup = (id) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("business_id", id)

        postData(Endpoints.CHECK_BUSINESS_SETUP, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }


    const approveBusiness = (id, verified) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("business_id", id)
        data.append("verified", verified)

        postData(Endpoints.VERIFY_BUSINESS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                    findBusiness(id)
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const sendMessageToBusiness = (id) => {
        if (isEmpty(message)) {
            Notify("Message is required", ERROR)
            return
        }

        setIsLoading(true)

        let data = new FormData()
        data.append("business_id", id)
        data.append("message", message)

        postData(Endpoints.SEND_MESSAGE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

      const sendAnyMessageToBusiness = () => {
        if (isEmpty(message) || isEmpty(phone)) {
            Notify("Message and phone is required", ERROR)
            return
        }

        setIsLoading(true)

        let data = new FormData()
        data.append("phone", phone)
        data.append("message", message)

        postData(Endpoints.SEND_SMS_MESSAGE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }


    return {
        isLoading,
        businesses,
        loadBusinesses,
        business,
        findBusiness,
        approveBusiness,
        sendMessageToBusiness,
        checkBusinessSetup,
        message,
        setMessage,
        phone,
        setPhone,
        sendAnyMessageToBusiness

    }
}

export default useBusinesses
