import PropTypes from 'prop-types'
import React from "react"

import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap"

import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"

import profile from "assets/images/profile-img.png"

import Copyright from "../../components/Copyright"
import useAuth from "../../hooks/useAuth"
import ProgressView from "../../components/ProgressView"
import MetaTitle from "../../components/MetaTitle"
import TextField from "../../components/TextField"

const Login = props => {


    const {email, setEmail, password, setPassword, error, logInUser, isLoading} = useAuth()

    return (
        <React.Fragment>
            <MetaTitle title={"Login"}/>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2"/>
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Back !</h5>
                                                <p>Sign in to access TreatMePal admin</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid"/>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div className="p-2 mt-2">

                                        {error !== "" ? (
                                            <Alert color="danger">{error}</Alert>
                                        ) : null}


                                        <TextField type="email"
                                                   className="form-control"
                                                   placeholder="Enter email address"
                                                   onChange={e => setEmail(e.target.value)}
                                                   value={email}
                                                   label={"Email address"}/>


                                        <TextField type="password"
                                                   className="form-control"
                                                   placeholder="Enter password"
                                                   onChange={e => setPassword(e.target.value)}
                                                   value={password}
                                                   label={"Password"}/>


                                        <div className="mt-3 d-grid">
                                            {isLoading ?
                                                <div className={"text-center"}>
                                                    <ProgressView/>
                                                </div>
                                                :
                                                <button
                                                    className="btn btn-primary btn-block waves-effect waves-light"
                                                    type="submit"
                                                    onClick={logInUser}
                                                >
                                                    Log In
                                                </button>
                                            }
                                        </div>

                                        {/*<div className="mt-4 text-center">*/}
                                        {/*    <Link to="/forgot-password" className="text-muted">*/}
                                        {/*        <i className="mdi mdi-lock me-1"/>*/}
                                        {/*        Forgot your password?*/}
                                        {/*    </Link>*/}
                                        {/*</div>*/}
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <Copyright/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const {error} = state.Login
    return {error}
}

export default withRouter(
    connect(mapStateToProps, {})(Login)
)

Login.propTypes = {
    error: PropTypes.any,
    history: PropTypes.object,
    loginUser: PropTypes.func,
    socialLogin: PropTypes.func
}
