import React, {useState} from "react"
import {postData} from "../util/Server"
import Endpoints from "../util/Endpoints"
import {ERROR, Notify, SUCCESS} from "../util/Notify"

const useCountries = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [countries, setCountries] = useState([])
    const [country, setCountry] = useState(null)

    const [name, setName] = useState('')
    const [currency, setCurrency] = useState('')
    const [code, setCode] = useState('')
    const [commission, setCommission] = useState('')
    const [activationAmount, setActivationAmount] = useState('')
    const [minWithdrawals, setMinWithdrawals] = useState('')
    const [minServiceCharge, setMinServiceCharge] = useState('')
    const [paymentMethods, setPaymentMethods] = useState('')
    const [payoutMethods, setPayoutMethods] = useState('')
    const [earningMaturity, setEarningMaturity] = useState('')
    const [businessReferralEarning, setBusinessReferralEarning] = useState('')
    const [businessReferralEarningPerBooking, setBusinessReferralEarningPerBooking] = useState('')
    const [businessReferralEarningInfluencer, setBusinessReferralEarningInfluencer] = useState('')
    const [businessReferralEarningPerBookingInfluencer, setBusinessReferralEarningPerBookingInfluencer] = useState('')
    const [userReferralCommission, setUserReferralCommission] = useState('')
    const [userReferralReward, setUserReferralReward] = useState('')
    const [userReferralCommissionInfluencer, setUserReferralCommissionInfluencer] = useState('')
    const [userReferralRewardInfluencer, setUserReferralRewardInfluencer] = useState('')
    const [userReferralMinSpend, setUserReferralMinSpend] = useState('')

    const createCountry = () => {
        if (!name || !currency || !code) {
            Notify('Name, currency and code are required', ERROR)
            return
        }

        setIsLoading(true)

        let data = new FormData()
        data.append("name", name)
        data.append("currency", currency)
        data.append("code", code)
        data.append("commission", commission)
        data.append("activation_amount", activationAmount)
        data.append("minimum_withdrawal_amount", minWithdrawals)
        data.append("minimum_service_charge", minServiceCharge)
        data.append("payment_methods", paymentMethods)
        data.append("payout_methods", payoutMethods)
        data.append("earning_maturity", earningMaturity)
        data.append("business_referral_earning", businessReferralEarning)
        data.append("business_referral_per_booking", businessReferralEarningPerBooking)
        data.append("influencer_business_referral_earning", businessReferralEarningInfluencer)
        data.append("influencer_business_referral_per_booking", businessReferralEarningPerBookingInfluencer)
        data.append("user_referral_commission", userReferralCommission)
        data.append("user_referral_reward_commission", userReferralReward)
        data.append("influencer_user_referral_commission", userReferralCommissionInfluencer)
        data.append("influencer_user_referral_reward_commission", userReferralRewardInfluencer)
        data.append("user_referral_minimum_spend", userReferralMinSpend)

        postData(Endpoints.CREATE_COUNTRY, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const loadCountries = (loadMore, query, active) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("status", active)
        data.append("query", query)

        postData(Endpoints.LIST_COUNTRIES, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                let list = loadMore ? countries : []
                list = list.concat(...res.data || [])
                setCountries(list)
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const findCountry = (id) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("id", id)

        postData(Endpoints.FIND_COUNTRY, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                setCountry(res.data)
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const activateCountry = (id, verified) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("id", id)
        data.append("status", verified)

        postData(Endpoints.VERIFY_COUNTRY, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                    findCountry(id)
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }


    return {
        isLoading,
        countries,
        loadCountries,
        country,
        findCountry,
        activateCountry,
        createCountry,
        name, setName,
        currency, setCurrency,
        code, setCode,
        commission, setCommission,
        activationAmount, setActivationAmount,
        minWithdrawals, setMinWithdrawals,
        minServiceCharge, setMinServiceCharge,
        paymentMethods, setPaymentMethods,
        payoutMethods, setPayoutMethods,
        earningMaturity, setEarningMaturity,
        businessReferralEarning, setBusinessReferralEarning,
        businessReferralEarningPerBooking, setBusinessReferralEarningPerBooking,
        businessReferralEarningInfluencer, setBusinessReferralEarningInfluencer,
        businessReferralEarningPerBookingInfluencer, setBusinessReferralEarningPerBookingInfluencer,
        userReferralCommission, setUserReferralCommission,
        userReferralReward, setUserReferralReward,
        userReferralCommissionInfluencer, setUserReferralCommissionInfluencer,
        userReferralRewardInfluencer, setUserReferralRewardInfluencer,
        userReferralMinSpend, setUserReferralMinSpend,

    }
}

export default useCountries
