import React from "react"
import {Redirect} from "react-router-dom"

import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

import Dashboard from "../pages/Dashboard/index"
import Categories from "../pages/Categories/Categories"
import CategoryDetails from "../pages/Categories/CategoryDetails"
import SubCategoryDetails from "../pages/Categories/SubCategoryDetails"
import Businesses from "../pages/Businesses/Businesses"
import BusinessDetail from "../pages/Businesses/BusinessDetail"
import NewCountry from "../pages/Countries/NewCountry"
import CountryDetail from "../pages/Countries/CountryDetail"
import Countries from "../pages/Countries/Countries"
import NewPackage from "../pages/Packages/NewPackage"
import PackageDetail from "../pages/Packages/PackageDetail"
import Packages from "../pages/Packages/Packages"


const userRoutes = [
    {path: "/dashboard", component: Dashboard},

    {path: "/dashboard/categories", component: Categories},
    {path: "/dashboard/categories/:id", component: CategoryDetails},
    {path: "/dashboard/sub-categories/:id", component: SubCategoryDetails},

    {path: "/dashboard/businesses", component: Businesses},
    {path: "/dashboard/businesses/:id", component: BusinessDetail},

    {path: "/dashboard/countries", component: Countries},
    {path: "/dashboard/countries/:id", component: CountryDetail},
    {path: "/dashboard/new-country", component: NewCountry},

    {path: "/dashboard/packages", component: Packages},
    {path: "/dashboard/packages/:id", component: PackageDetail},
    {path: "/dashboard/new-package", component: NewPackage},

    // this route should be at the end of all other routes
    {path: "/", exact: true, component: () => <Redirect to="/dashboard"/>},
]

const authRoutes = [
    {path: "/logout", component: Logout},
    {path: "/login", component: Login},
]

export {userRoutes, authRoutes}
