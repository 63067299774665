import React, {useEffect, useState} from "react"
import {postData} from "../util/Server"
import Endpoints from "../util/Endpoints"
import {useGlobal} from "../util/Store"
import useAuth from "./useAuth"
import {handleUserAccess} from "../util/Auth"


const useGetUser = (loadNow = true) => {
    const [isLoading, setIsLoading] = useState(false)
    const [user, setUser] = useState('')
    const [globalState, globalActions] = useGlobal()

    const {checkUser} = useAuth()

    useEffect(() => {
        if (loadNow) {
            loadUser()
        }
    }, [])

    const loadUser = () => {
        setIsLoading(true)
        postData(Endpoints.FIND_ME, {})
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    let user = res.data
                    setUser(res.data)

                    let newRes = {
                        data: {
                            user: user
                        }
                    }

                    handleUserAccess(newRes, globalActions, checkUser, false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    return {
        user,
        isLoading,
        setIsLoading,
        loadUser,
        globalState
    }
}

export default useGetUser