import React, {useState} from "react"

import {Card, CardBody, Container, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import classnames from "classnames"
import TMPButtonLink from "../../components/TMPButtonLink"
import CountriesTable from "../../components/Tables/CountriesTable"

const styles = {
    tabStyle: {
        cursor: "pointer"
    }
}

const Countries = props => {
    const [activeTab, setActiveTab] = useState(0)
    const toggle = tab => setActiveTab(tab)

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title="Countries"/>
                <Container fluid={true}>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Countries" {...props}/>
                    <Card>
                        <CardBody>

                            <div className="text-end mt-4 mb-4">
                                <TMPButtonLink to={"/dashboard/new-country"}>Add new country</TMPButtonLink>
                            </div>


                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={styles.tabStyle}
                                        className={classnames({
                                            active: activeTab === 0,
                                        })}
                                        onClick={() => toggle(0)}
                                    >
                                        Active
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={styles.tabStyle}
                                        className={classnames({
                                            active: activeTab === 1,
                                        })}
                                        onClick={() => toggle(1)}
                                    >
                                        Inactive
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={activeTab} className="p-3 text-muted">
                                <TabPane tabId={0}>
                                    <CountriesTable active={'active'}/>
                                </TabPane>
                                <TabPane tabId={1}>
                                    <CountriesTable active={'inactive'}/>
                                </TabPane>

                            </TabContent>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Countries
