import React, {useEffect, useState} from "react"
import {Modal} from "reactstrap"
import TMPButton from "../../components/TMPButton"
import ProgressView from "../../components/ProgressView"
import TextField from "../../components/TextField"
import useCategories from "../../hooks/useCategories"
import SelectView from "../../components/SelectView"

const NewSubCategoryModal = props => {
    const toggle = () => props.setOpen(!props.open)

    const {isLoading, name, setName, addSubCategory, gender, setGender} = useCategories()

    useEffect(() => {

    }, [props.open])

    return <Modal
        isOpen={props.open}
        toggle={toggle}
    >
        <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
                New sub category
            </h5>
            <button
                type="button"
                onClick={() => {
                    toggle()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            <TextField
                className="form-control"
                placeholder=""
                onChange={e => setName(e.target.value)}
                value={name}
                label={"Sub category name"}/>

            <SelectView
                label={"Gender"}
                onChange={e => {
                    setGender(e.target.value)
                }}
                value={gender}>
                <option>Any</option>
                <option>Male</option>
                <option>Female</option>
            </SelectView>

            <div>
                <div>
                    {isLoading ? <ProgressView/> :
                        <TMPButton
                            onClick={e => addSubCategory(props.item.id)}>Submit</TMPButton>}
                </div>

            </div>
        </div>
    </Modal>
}

export default NewSubCategoryModal