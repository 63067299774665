const STAGING_URL = "https://api-staging.treatmepal.com"
const PRODUCTION_URL = "https://api.treatmepal.com"
const DEVELOPMENT_URL = "http://localhost:9090"

export const isStagingEnvironment = () => window.location.host.includes("staging")
export const isLocalEnvironment = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || window.location.host.includes("dev")


export default {
    SERVER_HOST: isLocalEnvironment() ? DEVELOPMENT_URL : isStagingEnvironment() ? STAGING_URL : PRODUCTION_URL,
    WS_HOST: "",
    API_URL: '/api/v1/',
    FILE_URL: '/files/',
    WS: 'ws',
    REGISTER: 'user/register',
    UPDATE_PHONE: 'user/update/phone',
    UPDATE_ROLE: 'user/update/role',
    UPDATE_SITUATION: 'user/update/situation',
    UPDATE_DOSSIER: 'user/update/dossier',
    UPDATE_PREFERENCE: 'user/update/preferences',
    UPDATE_DOSSIER_STATUS: 'user/update/dossier-status',
    UPDATE_DOSSIER_VERIFIED: 'user/update/dossier-verified',
    UPDATE_PRO_SITUATION: 'user/update/pro-situation',
    LOGIN: 'admin/login',
    FORGOT_PASSWORD: 'admin/send-reset-code',
    RESET_CODE_PASSWORD: 'admin/reset-password',
    LOGOUT: 'user/logout',
    UPDATE_PHOTO: 'user/update/photo',
    UPDATE_PROFILE: 'user/update/profile',
    UPDATE_PASSWORD: 'user/update/password',
    UPDATE_PACKAGE: 'user/update/package',

    FIND_ME: 'admin/me',

    ADD_CATEGORY: 'categories/new',
    UPDATE_CATEGORY: 'categories/update/details',
    UPDATE_CATEGORY_PHOTO: 'categories/update/photo',
    LIST_CATEGORIES: 'categories/list',
    CATEGORY_DETAILS: 'categories/find',
    ADD_SUB_CATEGORY: 'sub-categories/new',
    LIST_SUB_CATEGORIES: 'sub-categories/list',
    SUB_CATEGORY_DETAILS: 'sub-categories/find',
    UPDATE_SUB_CATEGORY: 'sub-categories/update/details',

    LIST_BUSINESSES: "business/list/admin",
    FIND_BUSINESS: "business/find/admin",
    CHECK_BUSINESS_SETUP: "business/setup-admin",
    VERIFY_BUSINESS: "business/verify",
    SEND_MESSAGE: 'business/send-message',
    SEND_SMS_MESSAGE: 'business/send-sms-message',

    LIST_COUNTRIES: "country/list/admin",
    FIND_COUNTRY: "country/find/admin",
    VERIFY_COUNTRY: "country/verify",
    CREATE_COUNTRY: "country/create",

    LIST_SUBSCRIPTIONS: "subscription/list/admin",
    FIND_SUBSCRIPTION: "subscription/find/admin",
    VERIFY_SUBSCRIPTION: "subscription/activate/admin",
    CREATE_SUBSCRIPTION: "subscription/create"
}
