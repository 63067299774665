import React from "react"

const SelectView = (props) =>
    <div className="form-floating mb-3">
        <select className="form-select" {...props}>
            {props.children}
        </select>
        <label htmlFor="floatingSelectGrid">{props.label}</label>
    </div>

export default SelectView