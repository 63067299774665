import React, {useEffect, useState} from "react"

import {Card, CardBody, Container, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import classnames from "classnames"
import ProgressView from "../../components/ProgressView"
import {PageLoader} from "../../components/PageLoader"
import useBusinesses from "../../hooks/useBusinesses"
import TMPButton from "../../components/TMPButton"
import DetailsComponent from "../../components/DetailsComponent"
import TextField from "../../components/TextField"
import SendMessage from "./SendMessage"
import SendSMSMessage from "./SendSMSMessage"

const styles = {
    tabStyle: {
        cursor: "pointer"
    }
}


const BusinessDetail = ({match, location, ...rest}) => {
    const {id} = match.params
    const {
        findBusiness,
        business,
        isLoading,
        approveBusiness,
        sendMessageToBusiness,
        message,
        setMessage,
        checkBusinessSetup,
    } = useBusinesses()

    const [activeTab, setActiveTab] = useState(0)
    const toggle = tab => setActiveTab(tab)


    useEffect(() => {
        findBusiness(id)
        checkBusinessSetup(id)
    }, [])

    return (
        <React.Fragment>
            {business ?
                <div className="page-content">
                    <MetaTitle title={business.name}/>
                    <Container fluid={true}>
                        <Breadcrumbs title="Dashboard" breadcrumbItem={business.name} {...rest}/>

                        <Card>
                            <CardBody>

                                <div className={"mb-2 mt-2 d-flex justify-content-end"}>
                                    {isLoading ? <ProgressView/> :
                                        <TMPButton
                                            outline
                                            onClick={() => {
                                                approveBusiness(business.id, business?.verified === 1 ? 0 : 1)
                                            }}
                                        >
                                            {business?.verified === 1 ? "Unverify" : "Approve"}
                                        </TMPButton>}
                                </div>

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 0,
                                            })}
                                            onClick={() => toggle(0)}
                                        >
                                            Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 1,
                                            })}
                                            onClick={() => toggle(1)}
                                        >
                                            Bookings
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 2,
                                            })}
                                            onClick={() => toggle(2)}
                                        >
                                            Payments
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 3,
                                            })}
                                            onClick={() => toggle(3)}
                                        >
                                            Clients
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 4,
                                            })}
                                            onClick={() => toggle(4)}
                                        >
                                            Staff
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 5,
                                            })}
                                            onClick={() => toggle(5)}
                                        >
                                            Send a message
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 6,
                                            })}
                                            onClick={() => toggle(6)}
                                        >
                                            Send any message
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId={0}>
                                        <DetailsComponent
                                            details={[
                                                {title: "Name", des: business.name},
                                                {
                                                    title: "Link",
                                                    des: <a href={"https://treatmepal.com/" + business.link}
                                                            target={"_blank"}>View on TreatMePal</a>
                                                },
                                                {
                                                    title: "Subscription Status",
                                                    des: business.subscription_status?.toUpperCase()
                                                },
                                                {title: "Subscription Amount", des: business.subscription_amount},
                                                {title: "Contact Email", des: business.contact_email},
                                                {title: "Contact Phone", des: business.contact_phone},
                                                {title: "Website", des: ""},
                                            ]}
                                        />
                                    </TabPane>
                                    <TabPane tabId={5}>
                                        <SendMessage businessId={business.id}/>
                                    </TabPane>
                                    <TabPane tabId={6}>
                                        <SendSMSMessage/>
                                    </TabPane>
                                </TabContent>

                            </CardBody>
                        </Card>

                    </Container>
                </div>
                : <PageLoader/>
            }

        </React.Fragment>
    )
}

export default BusinessDetail
