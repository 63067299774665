import React, {useState} from "react"
import {postData} from "../util/Server"
import Endpoints from "../util/Endpoints"
import {ERROR, Notify, SUCCESS} from "../util/Notify"

const useSubscriptions = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [subscriptions, setSubscriptions] = useState([])
    const [subscription, setSubscription] = useState(null)

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [code, setCode] = useState('individual')
    const [summary, setSummary] = useState('')
    const [countryCode, setCountryCode] = useState('')
    const [price, setPrice] = useState('')
    const [staff, setStaff] = useState('')
    const [features, setFeatures] = useState('')

    const createSubscription = () => {
        if (!name || !countryCode || !code) {
            Notify('Name, currency and code are required', ERROR)
            return
        }

        setIsLoading(true)

        let data = new FormData()
        data.append("name", name)
        data.append("description", description)
        data.append("code", code)
        data.append("summary", summary)
        data.append("country_code", countryCode)
        data.append("price", price)
        data.append("staff", staff)
        data.append("features", features)


        postData(Endpoints.CREATE_SUBSCRIPTION, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const loadSubscriptions = (loadMore, query, active) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("status", active)
        data.append("query", query)

        postData(Endpoints.LIST_SUBSCRIPTIONS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                let list = loadMore ? subscriptions : []
                list = list.concat(...res.data || [])
                setSubscriptions(list)
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const findSubscription = (id) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("id", id)

        postData(Endpoints.FIND_SUBSCRIPTION, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                setSubscription(res.data)
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const activateSubscription = (id, verified) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("id", id)
        data.append("status", verified)

        postData(Endpoints.VERIFY_SUBSCRIPTION, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                    findSubscription(id)
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }


    return {
        isLoading,
        subscriptions,
        loadSubscriptions,
        subscription,
        findSubscription,
        activateSubscription,
        createSubscription,
        name, setName,
        description, setDescription,
        code, setCode,
        summary, setSummary,
        countryCode, setCountryCode,
        price, setPrice,
        staff, setStaff,
        features, setFeatures
    }
}

export default useSubscriptions
