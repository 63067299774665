import React, {useEffect} from "react"

import {Card, CardBody, Col, Container, Row} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import TextField from "../../components/TextField"
import ProgressView from "../../components/ProgressView"
import useSubscriptions from "../../hooks/useSubscriptions"
import FormContainer from "../../components/FormContainer"
import TMPButton from "../../components/TMPButton"
import SelectView from "../../components/SelectView"
import useCountries from "../../hooks/useCountries"

const NewPackage = props => {

    const {
        createSubscription,
        name, setName,
        description, setDescription,
        code, setCode,
        summary, setSummary,
        countryCode, setCountryCode,
        price, setPrice,
        staff, setStaff,
        features, setFeatures,
        isLoading
    } = useSubscriptions()

    const {loadCountries, isLoading: isLoadingCountries, countries} = useCountries()

    useEffect(() => {
        loadCountries(false, "", "active")
    }, [])

    useEffect(() => {
        if (countries.length > 0) {
            setCountryCode(countries?.[0]?.code)
        }
    }, [countries])


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title={"Create a package"}/>
                <Container fluid={true}>
                    <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Create a package"} {...props}/>

                    <FormContainer>
                        <Card>
                            <CardBody>
                                <h4 className="card-title mb-4">Fill in the form below to add a new package</h4>

                                <Row className={"mt-0"}>
                                    <Col md={12}>
                                        <TextField
                                            label={"Name"}
                                            onChange={e => setName(e.target.value)}
                                            value={name}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <SelectView
                                            label={"Country code"}
                                            onChange={e => setCountryCode(e.target.value)}
                                            value={countryCode}
                                        >
                                            {countries.map(ctry => <option value={ctry.code}>{ctry.name}</option>)}
                                        </SelectView>
                                    </Col>
                                    <Col md={6}>
                                        <SelectView
                                            label={"Select business size"}
                                            onChange={e => setCode(e.target.value)}
                                            value={code}
                                        >
                                            <option value={"individual"}>Individuals</option>
                                            <option value={"small_size"}>Small Businesses</option>
                                            <option value={"mid_size"}>Medium Businesses</option>
                                            <option value={"enterprise"}>Enterprises</option>
                                        </SelectView>
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Summary"}
                                            onChange={e => setSummary(e.target.value)}
                                            value={summary}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Price"}
                                            onChange={e => setPrice(e.target.value)}
                                            value={price}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Number of staff"}
                                            onChange={e => setStaff(e.target.value)}
                                            value={staff}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Features"}
                                            onChange={e => setFeatures(e.target.value)}
                                            value={features}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <TextField
                                            label={"Description"}
                                            onChange={e => setDescription(e.target.value)}
                                            value={description}
                                        />
                                    </Col>

                                </Row>

                                <hr/>

                                <div className={"float-end justify-content-center d-flex flex-row align-items-center"}>
                                    {(isLoading || isLoadingCountries) ?
                                        <ProgressView/> :
                                        <TMPButton
                                            extraClassName={"float-end"}
                                            onClick={createSubscription}>Create</TMPButton>}
                                </div>

                            </CardBody>
                        </Card>
                    </FormContainer>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NewPackage
