import React, {useEffect, useState} from "react"
import useCategories from "../../hooks/useCategories"
import TMPButton from "../../components/TMPButton"
import SubCategoriesTable from "../../components/Tables/SubCategoriesTable"
import NewSubCategoryModal from "./NewSubCategoryModal"

const SubCategories = ({category}) => {

    const {subCategoriesList, isLoading, loadSubCategories,globalState} = useCategories(false)
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => loadSubCategories(false, category?.id), [category, globalState.categoriesChange])

    return (
        <React.Fragment>
            <div className="text-center mt-4 mb-4">
                <TMPButton onClick={e => setOpenModal(true)}>Add new sub category</TMPButton>
            </div>
            <SubCategoriesTable isLoading={isLoading} list={subCategoriesList}/>
            <NewSubCategoryModal open={openModal} setOpen={setOpenModal} item={category}/>
        </React.Fragment>
    )
}

export default SubCategories
