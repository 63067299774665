import React, {useState} from "react"
import {useGlobal} from "../util/Store"
import {postData} from "../util/Server"
import Endpoints from "../util/Endpoints"
import {handleUserAccess} from "../util/Auth"
import Strings from "../util/Strings"
import {ERROR, Notify, SUCCESS} from "../util/Notify"
import {retrieveItem} from "../util/Storage"

const useAuth = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [globalState, globalActions] = useGlobal()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [accountType, setAccountType] = useState('tenant')
    const [fName, setFName] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [resetCode, setResetCode] = useState('')
    const [isLoggedIn, setIsLoggedIn] = useState(true)
    const [canSignup, setCanSignUp] = useState(false)
    const [referralCode, setReferralCode] = useState('')

    const [error, setError] = useState('')

    const logInUser = () => {
        setError('')

        if (!!(!email)) {
            setError('Email is required')
            return
        }

        if (!!(!password)) {
            setError('Password is required')
            return
        }

        setIsLoading(true)

        let data = new FormData()
        data.append("email", email)
        data.append("password", password)

        postData(Endpoints.LOGIN, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    handleUserAccess(res, globalActions, checkUser, true)
                } else {
                    if (Array.isArray(res.message)) {
                        setError(res.message[0])
                    } else {
                        setError(res.message)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                setError(Strings.CONNECTION_ERROR_MESSAGE)
                console.log(error)
            })

    }

    const registerUser = () => {
        setError('')

        if (!!(!fName)) {
            setError('Full name is required')
            return
        }

        if (!!(!email)) {
            setError('Email is required')
            return
        }

        if (!!(!password)) {
            setError('Password is required')
            return
        }


        if (password !== confirmPassword) {
            setError('Passwords do not match')
            return
        }

        setIsLoading(true)

        let data = new FormData()
        data.append("full_name", fName)
        data.append("email", email)
        data.append("password", password)
        data.append("role", accountType)
        data.append("referral_code", referralCode)

        postData(Endpoints.REGISTER, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    handleUserAccess(res, globalActions, checkUser, true)
                } else {
                    if (Array.isArray(res.message)) {
                        setError(res.message[0])
                    } else {
                        setError(res.message)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                setError(Strings.CONNECTION_ERROR_MESSAGE)
            })
    }

    const acceptInvitation = () => {
        setError('')

        if (!!(!fName)) {
            setError('Full name is required')
            return
        }


        if (!!(!password)) {
            setError('Password is required')
            return
        }


        if (password !== confirmPassword) {
            setError('Passwords do not match')
            return
        }

        setIsLoading(true)

        let data = new FormData()
        data.append("full_name", fName)
        data.append("email", globalState.currentUser.email)
        data.append("password", password)
        data.append("role", accountType)

        postData(Endpoints.ACCEPT_INVITATION, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    handleUserAccess(res, globalActions, checkUser, true)
                } else {
                    if (Array.isArray(res.message)) {
                        setError(res.message[0])
                    } else {
                        setError(res.message)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                setError(Strings.CONNECTION_ERROR_MESSAGE)
            })
    }

    const forgotPassword = () => {
        setError('')
        setSuccessMessage('')

        if (!!(!email)) {
            setError('Email is required')
            return
        }

        setIsLoading(true)

        let data = new FormData()
        data.append("email", email)

        postData(Endpoints.FORGOT_PASSWORD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    setSuccessMessage(res.data)
                } else {
                    if (Array.isArray(res.message)) {
                        setError(res.message[0])
                    } else {
                        setError(res.message)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                setError(Strings.CONNECTION_ERROR_MESSAGE)
            })

    }

    const resetPassword = () => {
        setError('')

        if (!!(!resetCode)) {
            setError('Code is required')
            return
        }

        if (!!(!password)) {
            setError('Password is required')
            return
        }


        if (password !== confirmPassword) {
            setError('Passwords do not match')
            return
        }


        setIsLoading(true)

        let data = new FormData()
        data.append("code", resetCode)
        data.append("password", password)

        postData(Endpoints.RESET_CODE_PASSWORD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                    setTimeout(() => window.location.assign("/login"), 1500)
                } else {
                    if (Array.isArray(res.message)) {
                        setError(res.message[0])
                    } else {
                        setError(res.message)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                setError(Strings.CONNECTION_ERROR_MESSAGE)
            })

    }

    const checkUser = (logInUser = true) => {
        let user = retrieveItem('user')

        if (user !== null) {
            user = JSON.parse(user)
            globalActions.setUserLoggedIn(logInUser)
            globalActions.setCurrentUser(user)
            globalActions.setAccountType(user.role)

            setIsLoggedIn(logInUser)
            return
        }

        setIsLoggedIn(false)
    }

    const resendCode = (e) => {
        e.preventDefault()

        setIsLoading(true)

        let data = new FormData()

        postData(Endpoints.RESEND_EMAIL_CODE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR)
                    } else {
                        Notify(res.message, ERROR)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                Notify("Cannot send request at this time", ERROR)
            })
    }

    const confirmCode = (code) => {
        setError('')

        if (!(!!code)) {
            setError("Missing confirmation code")
            return
        }

        setIsLoading(true)

        let data = new FormData()
        data.append("code", code)

        postData(Endpoints.CONFIRM_EMAIL, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    handleUserAccess(res, globalActions, checkUser, false)
                } else {
                    if (Array.isArray(res.message)) {
                        setError(res.message[0])
                    } else {
                        setError(res.message)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                setError(Strings.CONNECTION_ERROR_MESSAGE)
            })
    }

    const checkInvitationCode = (code) => {
        if (!(!!code)) {
            setError("Missing confirmation code")
            return
        }

        setIsLoading(true)
        setError('')

        let data = new FormData()
        data.append("code", code)

        postData(Endpoints.CHECK_INVITATION, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    handleUserAccess(res, globalActions, () => {
                        checkUser(false)
                        setCanSignUp(true)
                    }, false)
                } else {
                    if (Array.isArray(res.message)) {
                        setError(res.message[0])
                    } else {
                        setError(res.message)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                setError(Strings.CONNECTION_ERROR_MESSAGE)
            })
    }

    return {
        isLoading,
        setIsLoading,
        logInUser,
        referralCode,
        setReferralCode,
        email,
        setEmail,
        error,
        password,
        setPassword,
        globalState,
        accountType,
        setAccountType,
        fName,
        setFName,
        confirmPassword,
        setConfirmPassword,
        acceptTerms,
        setAcceptTerms,
        registerUser,
        forgotPassword,
        successMessage,
        resetCode,
        setResetCode,
        resetPassword,
        isLoggedIn,
        setIsLoggedIn,
        checkUser,
        confirmCode,
        resendCode,
        acceptInvitation,
        canSignup,
        checkInvitationCode
    }
}

export default useAuth