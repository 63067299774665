import React, {useEffect} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import ProgressView from "../ProgressView"
import {Link} from "react-router-dom"
import useSubscriptions from "../../hooks/useSubscriptions"

const SubscriptionsTable = ({active}) => {

    const {loadSubscriptions, isLoading, subscriptions} = useSubscriptions()

    useEffect(() => {
        loadSubscriptions(false, "", active)
    }, [active])

    return <>

        {isLoading &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!isLoading && subscriptions.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available at the moment</p>
        </div>
        }

        {!isLoading && subscriptions.length > 0 &&
        <div className="table-rep-plugin">
            <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
            >
                <Table
                    className="table table-striped table-bordered"
                >
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Code</Th>
                            <Th>Country</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {subscriptions.map((a, b) =>
                            <Tr key={b.toString()}>
                                <Th>
                                    {a.name}
                                </Th>
                                <Th>
                                    {a?.code}
                                </Th>
                                 <Th>
                                    {a?.country_code}
                                </Th>
                                <Td>
                                    <Link to={"/dashboard/packages/" + a.id}
                                          className="btn btn-primary btn-rounded waves-effect waves-light btn-sm">
                                        Details
                                    </Link>
                                </Td>
                            </Tr>
                        )}
                    </Tbody>

                </Table>
            </div>
        </div>
        }
    </>

}

export default SubscriptionsTable
