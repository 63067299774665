import React, {useEffect, useState} from "react"
import {ERROR, Notify, SUCCESS} from "../util/Notify"
import {postData} from "../util/Server"
import Endpoints from "../util/Endpoints"
import {useGlobal} from "../util/Store"

const useCategories = (loadData = false) => {
    const [globalState, globalActions] = useGlobal()
    const [name, setName] = useState('')
    const [photo, setPhoto] = useState('')
    const [active, setActive] = useState(true)

    const [gender, setGender] = useState('Any')

    const [isLoading, setIsLoading] = useState(false)
    const [categoriesList, setList] = useState([])
    const [category, setCategory] = useState('')

    const [subCategoriesList, setSubList] = useState([])
    const [subCategory, setSubCategory] = useState('')

    useEffect(() => {

        if (loadData) {
            loadCategories(false)
        }


    }, [globalState.categoriesChange])


    const loadCategory = (id) => {
        setIsLoading(true)
        let data = new FormData()
        data.append("id", id)

        postData(Endpoints.CATEGORY_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    setCategory(res.data || {})
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
            })
    }

    const addCategory = () => {
        setIsLoading(true)

        let data = new FormData()
        data.append("name", name)

        postData(Endpoints.ADD_CATEGORY, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify("Records updated successfully", SUCCESS)

                    setName("")

                    globalActions.setCategoriesChange(!globalState.categoriesChange)

                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR)
                    } else {
                        Notify(res.message, ERROR)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const updateCategory = (id) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("name", name)
        data.append("active", active)
        data.append("id", id)

        postData(Endpoints.UPDATE_CATEGORY, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify("Records updated successfully", SUCCESS)
                    globalActions.setCategoriesChange(!globalState.categoriesChange)
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR)
                    } else {
                        Notify(res.message, ERROR)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const updateCategoryPhoto = (id, gender) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("file", photo)
        data.append("id", id)
        data.append("gender", gender)

        postData(Endpoints.UPDATE_CATEGORY_PHOTO, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify("Records updated successfully", SUCCESS)
                    globalActions.setCategoriesChange(!globalState.categoriesChange)
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR)
                    } else {
                        Notify(res.message, ERROR)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const loadCategories = loadMore => {
        setIsLoading(true)

        let data = new FormData()
        data.append("active", 1)

        postData(Endpoints.LIST_CATEGORIES, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                let list = loadMore ? categoriesList : []
                list = list.concat(...res.data || [])
                setList(list)
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
            })
    }

    const loadSubCategory = (id) => {
        setIsLoading(true)
        let data = new FormData()
        data.append("id", id)

        postData(Endpoints.SUB_CATEGORY_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    setSubCategory(res.data || {})
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
            })
    }

    const addSubCategory = (id) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("name", name)
        data.append("id", id)
        data.append("gender", gender)

        postData(Endpoints.ADD_SUB_CATEGORY, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify("Records updated successfully", SUCCESS)

                    setName("")

                    globalActions.setCategoriesChange(!globalState.categoriesChange)

                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR)
                    } else {
                        Notify(res.message, ERROR)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const loadSubCategories = (loadMore, id) => {
        if (id === undefined) return

        setIsLoading(true)

        let data = new FormData()
        data.append("id", id)

        postData(Endpoints.LIST_SUB_CATEGORIES, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                let list = loadMore ? categoriesList : []
                list = list.concat(...res.data || [])
                setSubList(list)
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
            })
    }

    const updateSubCategory = (id) => {
        setIsLoading(true)

        let data = new FormData()
        data.append("name", name)
        data.append("active", active)
        data.append("gender", gender)
        data.append("id", id)

        postData(Endpoints.UPDATE_SUB_CATEGORY, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success === 1) {
                    Notify("Records updated successfully", SUCCESS)
                    globalActions.setCategoriesChange(!globalState.categoriesChange)
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR)
                    } else {
                        Notify(res.message, ERROR)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    return {
        addCategory,
        isLoading,
        globalState,
        name,
        setName,
        categoriesList,
        category,
        loadCategories,
        loadCategory,
        loadSubCategories,
        loadSubCategory,
        subCategoriesList,
        subCategory,
        addSubCategory,
        gender,
        setGender,
        updateCategory,
        active,
        setActive,
        photo,
        setPhoto,
        updateCategoryPhoto,
        updateSubCategory
    }
}

export default useCategories
