import React, {useState} from "react"

import {Card, CardBody, Container} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import CategoriesTable from "../../components/Tables/CategoriesTable"
import useCategories from "../../hooks/useCategories"
import NewCategoryModal from "./NewCategoryModal"
import TMPButton from "../../components/TMPButton"

const Categories = () => {

    const {categoriesList, isLoading} = useCategories(true)
    const [openModal, setOpenModal] = useState(false)

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title={"Categories"}/>
                <Container fluid={true}>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Categories"/>

                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-4">Manage categories and sub-categories</h4>

                            <div className="text-center mt-4 mb-4">
                                <TMPButton onClick={e => setOpenModal(true)}>Add new treatment category</TMPButton>
                            </div>

                            <CategoriesTable isLoading={isLoading} list={categoriesList}/>
                            <NewCategoryModal open={openModal} setOpen={setOpenModal}/>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Categories
