import React, {useState} from "react"
import PropTypes from 'prop-types'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle,} from "reactstrap"

//i18n
import {withTranslation} from "react-i18next"
// Redux
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"

// users
import {useGlobal} from "../../../util/Store"
import useProfile from "../../../hooks/useProfile"

const ProfileMenu = props => {

    const [menu, setMenu] = useState(false)

    const [globalState, globalActions] = useGlobal()

    const {updateRole, isLoading, logout} = useProfile()

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <img
                        className="rounded-circle header-profile-user"
                        src={globalState.currentUser?.photo}
                    />
                    <span className="d-none d-xl-inline-block ms-2 me-1">{globalState.currentUser.name}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem tag="a" href="#">
                        {" "}
                        <i className="bx bx-wrench font-size-16 align-middle me-1"/>
                        Account settings
                    </DropdownItem>


                    <div className="dropdown-divider"/>
                    <a href={"#"} onClick={e => {
                        e.preventDefault()
                        logout()
                    }} className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
                        <span>{props.t("Logout")}</span>
                    </a>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

ProfileMenu.propTypes = {
    success: PropTypes.any,
    t: PropTypes.any
}

const mapStatetoProps = state => {
    const {error, success} = state.Profile
    return {error, success}
}

export default withRouter(
    connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
