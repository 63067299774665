import {retrieveItem} from './Storage';
import Strings from './Strings';
import Endpoints from './Endpoints';

const BASE_URL = Endpoints.SERVER_HOST + Endpoints.API_URL;

let headers = new Headers();


let getDeviceName = () => {
    let ua = navigator.userAgent.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i),
        browser;

    if (navigator.userAgent.match(/Edge/i) || navigator.userAgent.match(/Trident.*rv[ :]*11\./i)) {
        browser = "msie";
    } else {
        browser = ua[1].toLowerCase();
    }
    return browser;
};


let buildHeaders = () => {
    resetHeaders();

    const item = retrieveItem('token');

    if (item != null) {
        headers.append('Authorization', `Bearer ${item}`);
    }

    headers.append('app_id', Strings.APP_ID);
    headers.append('device_id', navigator.appVersion);
    headers.append('device_name', getDeviceName());
    headers.append('Accept-Encoding', 'br');
    headers.set("timezone",  Intl.DateTimeFormat().resolvedOptions().timeZone)


    return true;
};

let resetHeaders = () => {
    headers.delete('Authorization');
    headers.delete('app_id');
    headers.delete('device_id');
    headers.delete('device_name');
    headers.delete('content-type');
};

export const getFileUrl = fileName => fileName?.startsWith("http") ? fileName : Endpoints.SERVER_HOST + Endpoints.FILE_URL + fileName;

export let fetchData = async (endPoint) => {
    const url = BASE_URL + endPoint;

    buildHeaders();

    return fetch(url, {method: 'GET', headers: headers});

};

export let postData = async (endPoint, data) => {
    const url = BASE_URL + endPoint;

    buildHeaders();

    // if (endPoint === Endpoints.UPLOAD) {
    //     headers.append('content-type', 'multipart/form-data')
    // }

    console.log(headers);

    return fetch(url, {method: 'POST', headers: headers, body: data});
};

export const getQueryVariable = variable => {
    let query = window.location.search.substring(1);

    let vars = query.split("&");

    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        console.log(pair)
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return "";
};

