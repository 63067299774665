import React, {useEffect, useState} from "react"

import {Card, CardBody, Container, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import useCategories from "../../hooks/useCategories"
import classnames from "classnames"
import EditSubCategory from "./EditSubCategory"

const styles = {
    tabStyle: {
        cursor: "pointer"
    }
}

const SubCategoryDetails = props => {

    const {id} = props.match.params

    const {subCategory, loadSubCategory, globalState} = useCategories(false)
    const [activeTab, setActiveTab] = useState(0)

    const toggle = tab => setActiveTab(tab)

    useEffect(() => loadSubCategory(id), [globalState.categoriesChange])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title={"Categories"}/>
                <Container fluid={true}>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Details"/>

                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-4">{subCategory?.name}</h4>


                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={{cursor: "pointer"}}
                                        className={classnames({
                                            active: activeTab === 0,
                                        })}
                                        onClick={() => toggle(0)}
                                    >
                                        Edit details
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={activeTab} className="p-3 text-muted">
                                <TabPane tabId={0}>
                                    <EditSubCategory category={subCategory}/>
                                </TabPane>
                            </TabContent>


                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default SubCategoryDetails
