import React from "react"
import TMPButton from "../../components/TMPButton"
import TextField from "../../components/TextField"
import ProgressView from "../../components/ProgressView"
import useBusinesses from "../../hooks/useBusinesses"

const SendMessage = ({businessId}) => {

    const {sendMessageToBusiness, message, setMessage, isLoading} = useBusinesses()


    return (
        <React.Fragment>

            <TextField
                textarea={true}
                className="form-control"
                onChange={e => setMessage(e.target.value)}
                value={message}
                label={"Write message"}/>


            <div>
                {isLoading ? <ProgressView/> :
                    <TMPButton
                        onClick={e => sendMessageToBusiness(businessId)}>Send</TMPButton>}
            </div>


        </React.Fragment>
    )
}

export default SendMessage
