import React, {useEffect, useState} from "react"
import useGetUser from "./useGetUser"
import {useGlobal} from "../util/Store"
import Endpoints from "../util/Endpoints"
import {postData} from "../util/Server"
import {ERROR, Notify, SUCCESS} from "../util/Notify"
import {handleUserAccess} from "../util/Auth"

const useProfile = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [globalState, globalActions] = useGlobal();
    const [photo, setPhoto] = useState('https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png')
    const [photoFile, setPhotoFile] = useState('')
    const [fName, setFName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [role, setRole] = useState('')

    const {} = useGetUser(true)

    useEffect(() => {
        if ((!!globalState.currentUser.photo)) {
            setPhoto(Endpoints.SERVER_HOST + Endpoints.FILE_URL + globalState.currentUser.photo)
        }

        setFName(globalState.currentUser.full_name)
        setEmail(globalState.currentUser.email)
        setRole(globalState.currentUser.role)

    }, [globalState.currentUser]);


    const logout = () => {
        window.localStorage.clear()
        globalActions.setUserLoggedIn(false)
        globalActions.setCurrentUser({})
        window.location.assign("/login")
    }

    const updateProfile = () => {
        setIsLoading(true)

        let data = new FormData();
        data.append("name", fName)
        data.append("email", email)

        postData(Endpoints.UPDATE_PROFILE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify("Details updated successfully", SUCCESS)
                    handleUserAccess(res, globalActions, false);
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    const updatePassword = () => {
        if (!!(!password)) {
            Notify('Current password is required', ERROR)
            return
        }


        if (newPassword !== confirmPassword) {
            Notify('Passwords do not match', ERROR)
            return
        }

        setIsLoading(true)

        let data = new FormData();
        data.append("current", password)
        data.append("new", newPassword)


        postData(Endpoints.UPDATE_PASSWORD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify("Password updated successfully", SUCCESS)
                    logout()
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    const updateRole = (newRole) => {
        setIsLoading(true)

        let data = new FormData();
        data.append("role", newRole)


        postData(Endpoints.UPDATE_ROLE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify("Role updated successfully", SUCCESS)
                    window.location.assign("/dashboard")
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    const updatePhoto = e => {
        e.preventDefault();

        if (!(!!photoFile)) {
            Notify('Please select a photo', ERROR);
            return;
        }

        let data = new FormData();
        data.append('file', photoFile);

        setIsLoading(true);

        postData(Endpoints.UPDATE_PHOTO, data)
            .then((res) => res.json())
            .then((res) => {
                console.log(res)
                setIsLoading(false);
                if (res.success === 1) {
                    Notify("Photo updated successfully", SUCCESS);
                    setTimeout(() => handleUserAccess(res, globalActions), 1000);
                } else {
                    Notify(res.message, ERROR);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
                Notify(Strings.CONNECTION_ERROR_MESSAGE, ERROR);
            });

    };

    return {
        isLoading,
        setIsLoading,
        setPhoto,
        setPhotoFile,
        setFName,
        photo,
        photoFile,
        setEmail,
        email,
        fName,
        globalState,
        updatePassword,
        updatePhoto,
        updateProfile,
        password,
        newPassword,
        confirmPassword,
        setPassword,
        setNewPassword,
        setConfirmPassword,
        role,
        setRole,
        updateRole,
        logout
    }
}

export default useProfile;