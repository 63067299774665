import React, {useEffect} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import ProgressView from "../ProgressView"
import {Link} from "react-router-dom"

const CategoriesTable = ({isLoading = false, list = []}) => {

    useEffect(() => {
    }, [])

    return <>

        {isLoading &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!isLoading && list.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available at the moment</p>
        </div>
        }

        {!isLoading && list.length > 0 &&
        <div className="table-rep-plugin">
            <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
            >
                <Table
                    className="table table-striped table-bordered"
                >
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Services</Th>
                            <Th>Status</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {list.map((a, b) =>
                            <Tr key={b.toString()}>
                                <Th>
                                    {a.name}
                                </Th>
                                 <Th>
                                    {a?.services ?? 0}
                                </Th>
                                 <Th>
                                    {a.active ? "ACTIVE" : "INACTIVE"}
                                </Th>
                                <Td>
                                    <Link to={"/dashboard/categories/" + a.id}
                                          className="btn btn-primary btn-rounded waves-effect waves-light btn-sm">
                                        Details
                                    </Link>
                                </Td>
                            </Tr>
                        )}
                    </Tbody>

                </Table>
            </div>
        </div>
        }
    </>

}

export default CategoriesTable